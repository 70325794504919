<template>
  <div
    :style="{
      minHeight: '100vh',
      backgroundColor: $store.state.fastPersonalizacao.aluno_background_color ? $store.state.fastPersonalizacao.aluno_background_color : '#fff',
    }"
  >
    <component :is="FastLoading" />
    
    <component :is="HomeInternoHeader" />
    <transition name="fast-fade">
    <component :is="HomeInternoContent" />
    </transition>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInterno",
  mixins: [methods],
  data: function() {
    return {
      paginaAtual: this.$route.name,
      fastTemplate: settings.fastTemplate,
      urlRedirectLogout: settings.urlRedirectLogout,
      fastLinkLive: "",
      fastDataAtual: "",
      HomeInternoContent: ""
    };
  },
  created: function () {
    if (this.$route.name.indexOf("HomeInternoAdmin") != "-1") this.paginaAtual = `admin/${this.$route.name}`
    else if (this.paginaAtual.indexOf("HomeInternoAluno") != "-1") this.paginaAtual = `aluno/${this.$route.name}`
    else if (this.paginaAtual.indexOf("HomeInternoResponsavel") != "-1") this.paginaAtual = `responsavel/${this.$route.name}`
    else if (this.paginaAtual.indexOf("HomeInternoSecretaria") != "-1") this.paginaAtual = `secretaria/${this.$route.name}`
    else if (this.paginaAtual.indexOf("HomeInternoTesouraria") != "-1") this.paginaAtual = `tesouraria/${this.$route.name}`
    else if (this.paginaAtual.indexOf("HomeInternoProfessor") != "-1") this.paginaAtual = `professor/${this.$route.name}`
  },
  computed: {  
    HomeInternoHeader() {
      return () => import(`@/templates/${this.fastTemplate}/HomeInternoHeader.vue`);
    },   
    FastLoading() {
      return () => import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },  
  mounted() { 
    // Valida layouts
    this.getLayoutsAluno().then(() => {
        this.verificaLayoutAluno();
        this.HomeInternoContent = () => import(`@/templates/${this.fastTemplate}/${this.paginaAtual}`)
    });
    this.getLayoutsAlunoLength();
    this.getPlataformaDadosPaises();
    // Valida sessão local, se existir fica verificando validade do token a cada 60s
    if (!this.validaSessao()) {
      window.location.href = this.urlRedirectLogout;
    } else {
      setTimeout(() => {
        this.validaToken(settings.endFastEad, settings.endApiFastEad);
      }, 5000);
      //this.getFastLivesDia();
    }
  },
  methods: {  
    async getFastLivesDia() {
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_live/lista_lives_dia", this.fastAjaxOptions("GET"));
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.getHoraServidor().then(() => {
            obj.some((live) => {
              if (live.status == "E") {
                this.fastLinkLive = "/Live/" + live.id_live;
                this.$toast.open({
                  message: "A live " + live.titulo_live + " iniciou na plataforma " + live.nome_plataforma + ". Clique para acessar.",
                  type: "info",
                  duration: 60000,
                  dismissible: true,
                  position: "bottom-right",
                  onClick: this.acessaLive,
                });
                return true;
              } else {
                this.fastLinkLive = "/Live/" + live.id_live;
                let dataInicio = new Date(live.data_live.split("T")[0] + " " + live.hora_live);
                let diff = Math.abs(dataInicio - this.fastDataAtual);
                let minutosInicio = Math.floor(diff / 1000 / 60);
                if (minutosInicio < 60) {
                  this.$toast.open({
                    message: "A live " + live.titulo_live + " irá iniciar em menos de uma hora na plataforma " + live.nome_plataforma,
                    type: "info",
                    duration: 10000,
                    dismissible: true,
                    position: "bottom-right",
                    onClick: this.acessaLive,
                  });
                }
                return true;
              }
            });
          });
        } else {
          this.fastLivesDia = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    acessaLive() {
      window.location.href = this.fastLinkLive;
    },
    // Pegar hora no servidor
    async getHoraServidor() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_usuario_curso_aula_atividade/lista_hora", this.fastAjaxOptions("GET"));
          let json = await resp.json();
          let obj = JSON.parse(json);
          this.fastDataAtual = new Date(obj.dataAtual);
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    },    
  },
  watch: {
    $route(to, from) {
        if (to.name.indexOf("HomeInternoAdmin") != "-1") this.paginaAtual = `admin/${to.name}`
        else if (to.name.indexOf("HomeInternoAluno") != "-1") this.paginaAtual = `aluno/${to.name}`
        else if (to.name.indexOf("HomeInternoResponsavel") != "-1") this.paginaAtual = `responsavel/${to.name}`
        else if (to.name.indexOf("HomeInternoSecretaria") != "-1") this.paginaAtual = `secretaria/${to.name}`
        else if (to.name.indexOf("HomeInternoTesouraria") != "-1") this.paginaAtual = `tesouraria/${to.name}`
        else if (to.name.indexOf("HomeInternoProfessor") != "-1") this.paginaAtual = `professor/${to.name}`
        else this.paginaAtual = to.name

        this.verificaLayoutAluno();
        this.HomeInternoContent = () => import(`@/templates/${this.fastTemplate}/${this.paginaAtual}`)  
      
    }
  }
};
</script>

<style scope>
.fast-fade-enter-active {
  transition: all .5s ease;
}
.fast-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(5px);
  opacity: 0;
}
</style>
